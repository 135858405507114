<template>
  <b-modal
      id="ktb-mobile-app-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ KTB Mobile App"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
              v-slot="{ errors }"
              name="deviceId"
              rules="required|length:41"
          >
            <b-form-group label="Device ID">
              <b-form-input
                  v-model="form.deviceId"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  maxlength="41"
                  placeholder="Device ID"
                  type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" name="pin" rules="required|length:6|numeric">
            <b-form-group label="พิน 6 หลัก">
              <b-form-input
                  v-model="form.pin"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  maxlength="6"
                  placeholder="123456 PIN"
                  type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="text-right">
            <a class="btn btn-primary mr-2" download href="/ktb/ktb-next.apk">
              <i class="uil-download-alt"></i>
              ดาวน์โหลดแอพ
            </a>
            <b-button :disabled="isFetching" type="submit" variant="success">
              {{ $t('buttons.submit') }}
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'KtbAutoMobileAppModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    ktbAccount: {
      type: Object,
      default: () => ({
        deviceId: '',
        pin: '',
      })
    }
  },
  data() {
    return {
      form: {
        deviceId: '',
        pin: '',
      },
      isFetching: false
    }
  },
  created() {
    this.form = {
      deviceId: this.ktbAccount?.deviceId || '',
      pin: this.ktbAccount?.pin || '',
    }
  },
  methods: {
    ...mapActions(['fetchBankAccount', 'submitKTB']),
    async onSubmit() {
      this.isFetching = true
      const response = await this.submitKTB({ id: this.agentBankAccountId, data: this.form })
      if(response) {
        this.$bvModal.hide('ktb-mobile-app-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    },
  }
})
</script>

<style scoped>

</style>